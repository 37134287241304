<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
            <div class="d-flex p-0">
                <div class="card-title row w-100">
                    <div class="d-flex p-0 align-items-center position-relative mb-4">
                        <span class="text-gray-500 fw-bolder text-hover-primary rounded-1 cursor-pointer me-6 fs-6" @click="router.go(-1)"><i class="fas fa-arrow-left me-2 text-gray-500 fw-bolder"></i>BACK</span>
                    </div>
                    <div class="d-flex flex-column p-0 position-relative mb-4" v-if="!loadingTable">
                        <div class="row mb-1">
                            <div class="col-2 text-gray-500 fw-bolder me-6 fs-6">Kode Distribusi</div>
                            <div class="col text-gray-500 fw-bolder me-6 fs-6">: <span class="ms-2">{{ `${tempData.kode_dist} (${tableData.length})` }}</span></div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-2 text-gray-500 fw-bolder me-6 fs-6">Jenis</div>
                            <div class="col text-gray-500 fw-bolder me-6 fs-6 text-uppercase">: 
                                <span class="ms-2">{{ `${tempData.jenis}` }}</span>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-2 text-gray-500 fw-bolder me-6 fs-6">Keterangan</div>
                            <div class="col text-gray-500 fw-bolder me-6 fs-6">: <span class="ms-2">{{ `${tempData.note}` }}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body pt-0 tab-content sticky">
            <div v-if="tempData.jenis == 'item'"  class="table-fixed-header">
                <table class="table table-rounded table-row-dashed" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-100px">#</th>
                            <th>Brand</th>
                            <th>Kategori</th>
                            <th>Artikel</th>
                            <th>SKU</th>
                            <th>Warna</th>
                            <th>Ukuran</th>
                            <th class="text-end w-150px">Dikirim</th>
                            <th class="text-end w-150px" v-if="tempData.status != 'PENDING'">Diterima</th>
                            <th class="text-end w-150px" v-if="tempData.status != 'PENDING'">Status</th>
                            <th class="text-end" v-if="tempData.status == 'PENDING'">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i">
                            <td class="w-100px">{{ i+1 }}</td>
                            <td>{{ item.brand_name }}</td>
                            <td>{{ item.raw_category }}</td>
                            <td>{{ item.artikel }}</td>
                            <td>{{ item.sku }}</td>    
                            <td>{{ item.color }}</td>
                            <td>{{ item.size }}</td>
                            <td class="text-end w-150px">
                                <input v-if="tableData[item.index].editable == true" type="number" class="form-control w-100px" placeholder="Qty" v-model="edit_row_qty"/>
                                <span class="text-end" v-else>{{ item.qty_sent }}</span>
                            </td>
                            <td class="text-end w-150px" v-if="tempData.status != 'PENDING'">{{ item.qty_received }}</td>
                            <td class="text-end" v-if="tempData.status != 'PENDING'">
                                <div class="form-check form-check-custom form-check-solid justify-content-end">
                                    <input class="form-check-input" :class="{'bg-success': item.qty_sent === item.qty_received, 'bg-warning': item.qty_sent > item.qty_received || item.qty_sent < item.qty_received}" type="checkbox" :value="true" disabled checked="checked">
                                </div>
                            </td>
                            <td class="text-end" v-if="tempData.status == 'PENDING'">
                                <button v-if="tableData[item.index].editable == false" class="btn btn-light btn-active-light-primary btn-sm" @click="editRow(item)">Edit</button>
                                <div v-else class="flex">
                                    <button :disabled="disabledButton" class="btn btn-light btn-active-light-danger btn-sm mx-1" @click="tableData[item.index].editable = false">Batal</button>
                                    <button 
                                        class="btn btn-light btn-active-light-primary btn-sm mx-1"
                                        :disabled="disabledButton"
                                        :data-kt-indicator="loadingButton ? 'on' : 'off'" 
                                        @click="saveRow(item)"
                                    >
                                        <span class="indicator-label"> Simpan </span>

                                        <span class="indicator-progress">
                                            Please wait...
                                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr class="text-start fw-boldest fs-7 gs-0" v-if="tableData.length > 0">
                            <td colspan="7">Total</td>
                            <td class="text-end w-150px">{{ tableData.map(o => o.qty_sent).reduce((p,n) => p + n, 0) }}</td>
                            <td class="text-end w-150px" v-if="tempData.status != 'PENDING'">{{ tableData.map(o => o.qty_received).reduce((p,n) => p + n, 0) }}</td>
                            <td></td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <div v-else class="table-fixed-header">
                <table class="table table-rounded table-row-dashed" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-100px">#</th>
                            <th>Gambar</th>
                            <th>Desain ID</th>
                            <th>Folder</th>
                            <th>Ukuran Print</th>
                            <th class="text-end w-150px">Dikirim</th>
                            <th class="text-end w-150px" v-if="tempData.status != 'PENDING'">Diterima</th>
                            <th class="text-end w-150px" v-if="tempData.status != 'PENDING'">Status</th>
                            <th class="text-end" v-if="tempData.status == 'PENDING'">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i">
                            <td class="w-100px">{{ i+1 }}</td>
                            <td>
                                <img class="tbl-image" @click="viewImage(item)" :src="img_url_desain+item.gambar">
                            </td>
                            <td>{{ item.desain_id }}</td>
                            <td>{{ item.folder }}</td>
                            <td>{{ item.print_size }}</td>    
                            <td class="text-end w-150px">
                                <input v-if="tableData[item.index].editable == true" type="number" class="form-control w-100px" placeholder="Qty" v-model="edit_row_qty"/>
                                <span class="text-end" v-else>{{ item.qty_sent }}</span>
                            </td>
                            <td class="text-end w-150px" v-if="tempData.status != 'PENDING'">{{ item.qty_received }}</td>
                            <td class="text-end" v-if="tempData.status == 'GAGAL'">
                                <div class="form-check form-check-custom form-check-solid justify-content-end">
                                    <i class="fas fa-times text-danger"></i>
                                </div>
                            </td>
                            <td class="text-end" v-if="tempData.status != 'PENDING' && tempData.status != 'GAGAL'"> 
                                <div class="form-check form-check-custom form-check-solid justify-content-end">
                                    <input class="form-check-input" :class="{'bg-success': item.qty_sent === item.qty_received, 'bg-warning': item.qty_sent > item.qty_received || item.qty_sent < item.qty_received}" type="checkbox" :value="true" disabled checked="checked">
                                </div>
                            </td>
                            <td class="text-end" v-if="tempData.status == 'PENDING'">
                                <button v-if="tableData[item.index].editable == false" class="btn btn-light btn-active-light-primary btn-sm" @click="editRow(item)">Edit</button>
                                <div v-else class="flex">
                                    <button :disabled="disabledButton" class="btn btn-light btn-active-light-danger btn-sm mx-1" @click="tableData[item.index].editable = false">Batal</button>
                                    <button 
                                        class="btn btn-light btn-active-light-primary btn-sm mx-1"
                                        :disabled="disabledButton"
                                        :data-kt-indicator="loadingButton ? 'on' : 'off'" 
                                        @click="saveRow(item)"
                                    >
                                        <span class="indicator-label"> Simpan </span>

                                        <span class="indicator-progress">
                                            Please wait...
                                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr class="text-start fw-boldest fs-6 gs-0" v-if="tableData.length > 0">
                            <td colspan="5"></td>
                            <td class="text-end w-150px">{{ tableData.map(o => o.qty_sent).reduce((p,n) => p + n, 0) }}</td>
                            <td class="text-end w-150px" v-if="tempData.status != 'PENDING'">{{ tableData.map(o => o.qty_received).reduce((p,n) => p + n, 0) }}</td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
        </div>
        
        <el-dialog v-model="getZoomImage.visibility" :title="getZoomImage.title" width="50%" lock-scroll top="5vh">
            <inner-image-zoom :src="getZoomImage.src"/>
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRoute, useRouter } from 'vue-router'
import InnerImageZoom from 'vue-inner-image-zoom';
//import { InfoFilled } from '@element-plus/icons-vue'


export default defineComponent({
    components: {
        InnerImageZoom,
    },
    setup() {
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)
        const img_url_product = ref(process.env.VUE_APP_IMAGE_PRODUCTS)

        const [route, router] = [useRoute(), useRouter()]

        const loadingTable = ref(true)
        const loadingButton = ref(false)
        const disabledButton = ref(true)
        const loadingPopover = ref(false)

        const modalVisible = ref(false)
        const modalTitle = ref('')

        const tableData = reactive([])
        const totalRecordCount = ref(0)
        
        const tableHeader = reactive([]);

        const tempData = reactive({
            kode_dist: '',
            jenis: '',
            note: '',
            pengirim: '',
            penerima: '',
            tanggal: '',
            status: '',
        })

        const edit_row_qty = ref('')

        const editRow = async (data) => {
            // Checking valid single row
            let onlySingleRow = tableData.some(o => o.editable == true)

            if(onlySingleRow) {
                Swal.fire({
                    title: "Tidak bisa edit multi baris",
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 3000,
                })

                return
            }
            
            disabledButton.value = false

            tableData[data.index]['editable'] = true
            edit_row_qty.value = tableData[data.index]['qty']
        }

        const saveRow = async (row) => {
            loadingButton.value = true
            disabledButton.value = true

            let tempQty = tableData[row.index]['qty']

            tableData[row.index].qty = edit_row_qty.value
            
            await ApiService.setHeader();
            await ApiService.post("distribusi/revisi", { data: tableData[row.index], kode_dist: tempData.kode_dist })
            .then(({ data }) => { 
                if(data) {
                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 3000,
                    })

                    tableData[row.index].qty = edit_row_qty.value
                    tableData[row.index].editable = false

                    //getDistribusiDetail()
                }
            })
            .catch(({ response }) => {
                if(response) {
                    Swal.fire({
                        title: "Update qty gagal",
                        icon: "error",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 3000,
                    })

                    tableData[row.index]['qty'] = tempQty
                    edit_row_qty.value = tableData[row.index]['qty']
                    console.log('Error while updating raw product distribusi at '+tempData.kode_dist);
                }
            });

            loadingButton.value = false
            disabledButton.value = false
            console.log(tableData[row.index])
        }

        const filterActive = ref(false)
        const filter = reactive({
            kode_dist: null,
        })
                
        const onFilter = async () => {
            if(Object.values(filter).every(o => o == null || o.length == 0)) {
                return
            }

            filterActive.value = false

            loadingTable.value = true

            await getDistribusiDetail()

            loadingTable.value = false

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async () => {   
            loadingTable.value = true

            filter.desain_id = null
            filter.folder_id = null

            filterActive.value = false

            await getDistribusiDetail()

            loadingTable.value = true
        }
         
        const getZoomImage = reactive({
            title: '',
            src: '',
            visibility: false,
        })

        const viewImage = (data) => {
            getZoomImage.visibility = true
            getZoomImage.title = data.desain_id
            getZoomImage.src = img_url_desain.value+data.gambar
        }

        const getDistribusiDetail = async() => {
            await tableData.splice(0)
            loadingTable.value = true
            
            await ApiService.setHeader();
            await ApiService.post("distribusi/detail/"+route.params.kode, { filter: filter })
            .then(async({ data }) => {
                totalRecordCount.value = data.data.length

                for(let i=0; i<data.data.length; i++) {
                    data.data[i].index = i;
                    data.data[i].editable = false;
                }
                
                tempData.kode_dist = data.kode_dist
                tempData.note = data.note
                tempData.jenis = data.jenis
                tempData.tanggal = data.tanggal
                tempData.pengirim = data.pengirim
                tempData.penerima = data.penerima
                tempData.status = data.status

                if(data.status == 'PENDING') {
                    tableHeader.push({name: "Action",key: "action"})
                }
                else {
                    tableHeader.push({name: "Diterima", key: "qty_received"}, {name: "Status", key: "status"})
                }
                
                Object.assign(tableData, data.data)
            })
            .catch(({ response }) => {
                console.log('Error getting history distribusi');
            });
            
            loadingTable.value = false
        }

        onMounted(() => {
            setCurrentPageBreadcrumbs("Detail Distribusi", ["distribusi", "history", route.params.kode]);

            getDistribusiDetail()
        })

        watchEffect(() => {
            console.log(tableData)
        })

        return {
            route, router,
            loadingTable, img_url_desain, modalVisible, modalTitle, disabledButton, loadingButton,
            filter, filterActive, onFilter, resetFilter,
            getZoomImage, viewImage,
            tableHeader, tableData, totalRecordCount, tempData, edit_row_qty, editRow, saveRow,
            formatIDR,
        }
    }
})
</script>